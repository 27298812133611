//core
import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";

//rsuite
import {
  Button,
  CheckPicker,
  Checkbox,
  DatePicker,
  DateRangePicker,
  Form,
  InputNumber,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
const { combine, after, before, beforeToday } = DateRangePicker;

//components
import RepeatForm from "./repeatForm";

//helper
import { fetcher } from "../../../utils/fetcher";
import { ordinalize } from "../../../utils/ordinalize";
import { GENDER_OPTIONS, STUDENT_AGE_GROUPS } from "../../../utils/constants";
import { repeatReminderSummary } from "../../../utils/helpers";
import { flipper } from "../../../utils/flipper";
import {
  format_date,
  date_after_days,
  date_before_days,
} from "../../../utils/date_formatter";
import CustomAgeGroupView from "../../../components/custom_age_group";

const Schedule = ({ state, dispatch, onClose, id, reminders, editable }) => {
  const disableCondition = id && reminders && !editable;

  const { data: featuresData = { features: [] } } = useSWR(
    `/flipper/api/features`,
    fetcher,
  );

  const picker = React.useRef();
  const navigate = useNavigate();
  const { data: grades = { data: [] } } = useSWR("/api/v0/grades", fetcher);
  const { data: reminderStates = { data: [] } } = useSWR("/api/v0/reminders/states", fetcher);
  const { data: courses = { data: [] }} = useSWR(
    {
      url: "/api/v0/courses",
      params: {
        grade_id: disableCondition
          ? reminders?.filters?.grade_id
          : state?.filters?.gradeId,
        published: true,
      },
    },
    fetcher
  );
  const { data: lessons = { data: [] } } = useSWR(
    {
      url: "/api/v0/lessons",
      params: {
        course_id: disableCondition
          ? reminders?.filters?.active_enrollment_course_id
          : state?.filters?.active_enrollment_course_id,
      },
    },
    fetcher
  );

  const { data: languagesData } = useSWR({ url: "/api/v0/languages" }, fetcher);
  const { data: locationsData } = useSWR({ url: "/api/v0/locations" }, fetcher);

  const [repeatReminder, setRepeatReminder] = useState(false);

  const filters = [
    {
      name: "ageGroups",
      label: "Age Groups",
      data: STUDENT_AGE_GROUPS,
      value: disableCondition
        ? reminders?.filters?.ageGroups
        : state?.filters?.ageGroups,
      searchable: false,
      placeholder: "Select",
    },
    {
      name: "gender",
      label: "Gender",
      data: GENDER_OPTIONS,
      value: disableCondition
        ? reminders?.filters?.gender
        : state?.filters?.gender,
      searchable: false,
      placeholder: "Select",
    },
    {
      name: "language",
      label: "Preferred Language",
      data: languagesData
        ? languagesData["data"].map((language) => ({
            value: language.id,
            label: language.attributes.name,
          }))
        : [],
      value: disableCondition
        ? reminders?.filters?.language_id
        : state?.filters?.language_id,
      searchable: false,
      placeholder: "Select",
    },
    {
      name: "country",
      label: "Country",
      data: locationsData
        ? Object.keys(locationsData?.countries)?.map((location) => ({
            value: location,
            label: location,
          }))
        : [],
      value: disableCondition
        ? reminders?.filters?.country
        : state?.filters?.country,
      searchable: true,
      placeholder: "Select",
    },
    {
      name: "grades",
      label: "Course Grades",
      data: grades?.data?.map((grade) => ({
        value: grade.id,
        label: ordinalize(grade.attributes.name),
      })),
      value: disableCondition
        ? reminders?.filters?.grade_id
        : state?.filters?.gradeId,
      searchable: false,
      placeholder: "Select",
    },
    {
      name: "course",
      label: "Actively Enrolled in Course",
      data: courses?.data?.map((course) => ({
        value: course.id,
        label: course.attributes.title,
      })),
      value: disableCondition
        ? reminders?.filters?.active_enrollment_course_id
        : state?.filters?.active_enrollment_course_id,
      searchable: true,
      placeholder: "Select",
    },
    {
      name: "lesson",
      label: "Inactive Since Lesson Completed",
      data: lessons?.data?.map((lesson) => ({
        value: lesson.id,
        label: lesson.attributes.title,
      })),
      value: disableCondition
        ? reminders?.filters?.inactive_since_lesson_id
        : state?.filters?.inactive_since_lesson_id,
      searchable: false,
      placeholder: "Select",
    },
  ];

  const handleChange = (newValue, previousValue, name) => {
    switch (name) {
      case "ageGroups":
        dispatch({
          type: "setAgeGroups",
          payload: newValue,
        });
        break;
      case "grades":
        dispatch({
          type: "setGradeIds",
          payload: Array.from(new Set([...newValue])),
        });
        break;
      case "provinces":
        dispatch({
          type: "setProvinceIds",
          payload: Array.from(new Set([...newValue])),
        });
        break;
      case "language":
        dispatch({
          type: "setLanguageIds",
          payload: Array.from(new Set([...newValue])),
        });
        setErrors({ ...errors, language: null });
        break;
      default:
      // others
    }
  };

  const handleSingleChange = (newValue, name) => {
    switch (name) {
      case "country":
        dispatch({
          type: "setCountry",
          payload: newValue,
        });
        break;
      case "gender":
        dispatch({
          type: "setGender",
          payload: newValue,
        });
        break;
      case "course":
        dispatch({
          type: "setActiveEnrollmentCourseId",
          payload: newValue,
        });
        break;
      case "lesson":
        dispatch({
          type: "setInactiveSinceLessonId",
          payload: newValue,
        });
        break;
      default:
      // others
    }
  };

  const handleCheckAll = (name, value, checked) => {
    switch (name) {
      case "ageGroups": {
        let ageGroups = checked
          ? STUDENT_AGE_GROUPS.map((ageOption) => ageOption.value) ?? []
          : [];
        dispatch({
          type: "setAgeGroups",
          payload: ageGroups,
        });
        break;
      }
      case "grades": {
        let gradeIds = checked
          ? grades?.data?.map((grade) => grade?.id) ?? []
          : [];
        dispatch({
          type: "setGradeIds",
          payload: gradeIds,
        });
        break;
      }
      case "language": {
        let languageIds = checked
          ? languagesData?.data?.map((language) => language.id) ?? []
          : [];
        dispatch({
          type: "setLanguageIds",
          payload: languageIds,
        });
        break;
      }
      case "provinces": {
        let provinces = checked
          ? Object.keys(locationsData?.afg_states)?.map(
              (location) => location,
            ) ?? []
          : [];
        dispatch({
          type: "setProvinceIds",
          payload: provinces,
        });
        break;
      }
      default:
      // others
    }
  };

  const handleClean = (name) => {
    switch (name) {
      case "grades": {
        dispatch({
          type: "setGradeIds",
          payload: [],
        });
        break;
      }
      case "locations": {
        dispatch({
          type: "setLocationId",
          payload: "",
        });
        break;
      }
      case "provinces": {
        dispatch({
          type: "setProvinceIds",
          payload: [],
        });
        break;
      }
      case "courses": {
        dispatch({
          type: "setCourseIds",
          payload: [],
        });
        break;
      }
      case "ageGroups": {
        dispatch({
          type: "setAgeGroups",
          payload: [],
        });
        break;
      }
      case "gender": {
        dispatch({
          type: "setGender",
          payload: "",
        });
        break;
      }
      case "courseType": {
        dispatch({
          type: "setCourseType",
          payload: "graded",
        });
        break;
      }
      default:
      // others
    }
  };

  const [errors, setErrors] = useState({
    language: null,
    type: null,
    start_date: null,
    start_time: null,
    inactive_date: null,
    inactive_days: null,
  });
  const SingleSelectionFilters = ["country", "gender", "course", "lesson"];

  const AFG_STATES = locationsData
    ? Object.keys(locationsData?.afg_states)?.map((location) => ({
        value: location,
        label: location,
      }))
    : [];

  const validateValues = (inactive, active, errorField) => {
    if ((inactive && !active) || (active && !inactive)) {
      setErrors({
        ...errors,
        [errorField]:
          "Both the inactive and active need to have value OR leave them empty to skip this filter",
      });
      return true;
    }
    return false;
  };

  const onSubmit = () => {
    const { filters, schedule } = state;
    const remindersOrFilters = disableCondition ? reminders?.filters : filters;
    const remindersOrSchedule = disableCondition
      ? reminders?.schedule
      : schedule;

    if (!remindersOrFilters?.language_id?.length) {
      setErrors({
        ...errors,
        language: "Please select preferred languages.",
      });
      return;
    }

    if (!remindersOrSchedule?.type?.length) {
      setErrors({
        ...errors,
        type: "Please select occurance type.",
      });
      return;
    }

    if (remindersOrSchedule?.type == 'state') {
      if (!remindersOrSchedule?.state) {
        setErrors({
          ...errors,
          state: "Please select state.",
        });
        return;
      }
    } else {
      if (!remindersOrSchedule?.start_date) {
        setErrors({
          ...errors,
          start_date: "Please select date.",
        });
        return;
      }

      if (
        remindersOrSchedule?.end_date &&
        remindersOrSchedule?.start_date > remindersOrSchedule?.end_date
      ) {
        setErrors({
          ...errors,
          start_date: "Please select a date on or before the end date.",
        });
        return;
      }

      if (!remindersOrSchedule?.start_time && !disableCondition) {
        setErrors({
          ...errors,
          start_time: "Please select time.",
        });
        return;
      }
    }

    if (
      validateValues(
        remindersOrFilters?.inactive_since_date,
        remindersOrFilters?.active_since_date,
        "inactive_date",
      ) ||
      validateValues(
        remindersOrFilters?.inactive_since_days,
        remindersOrFilters?.active_since_days,
        "inactive_days",
      )
    )
      return;

    if (
      remindersOrFilters?.inactive_since_date &&
      new Date(format_date(remindersOrFilters?.inactive_since_date)) >=
        new Date(format_date(remindersOrSchedule?.start_date))
    ) {
      setErrors({
        ...errors,
        inactive_date:
          "Inactive since date needs to be before the schedule for date",
      });
      return;
    }

    if (
      remindersOrFilters?.inactive_since_date &&
      new Date(format_date(remindersOrFilters?.inactive_since_date)) <=
        new Date(format_date(remindersOrFilters?.active_since_date))
    ) {
      setErrors({
        ...errors,
        inactive_date:
          "Active since date needs to be before the Inactive since date",
      });
      return;
    }

    if (
      remindersOrFilters?.inactive_since_days &&
      remindersOrFilters?.inactive_since_days >=
        remindersOrFilters?.active_since_days
    ) {
      setErrors({
        ...errors,
        inactive_days:
          "Active since days need to be greater than the Inactive since days",
      });
      return;
    }

    navigate("/reminders/content");
    setErrors({});
  };

  useEffect(() => {
    if (state.copied || !id || !editable || !reminders) return;
    dispatch({ type: "copyState", payload: reminders });
  }, [reminders]);

  return (
    <div className="schedule">
      <div className="sm-container">
        <Form onSubmit={onSubmit}>
          <div className="row mg-row">
            {filters?.map((item, index) => {
              return (
                <>
                  <div key={index} className="col-md-6">
                    <div className="form-group ">
                      <label className="f-med mb-1" htmlFor={item.label}>
                        {item.label}
                      </label>
                      {item.name == "language" && (
                        <span className="text-danger">*</span>
                      )}
                      {SingleSelectionFilters?.includes(item?.name) ? (
                        <SelectPicker
                          name={item.label}
                          className="w-100"
                          ref={picker}
                          block
                          disabled={disableCondition}
                          value={item.value}
                          data={item.data}
                          placement="autoVerticalStart"
                          menuMaxHeight={200}
                          style={{ width: 224 }}
                          searchable={item.searchable}
                          placeholder="Select"
                          countable={false}
                          onChange={(newValue) =>
                            handleSingleChange(newValue, item.name)
                          }
                          onClean={() => handleClean(item.name)}
                        />
                      ) : (
                        <CheckPicker
                          className="w-100"
                          placement="autoVerticalStart"
                          menuMaxHeight={200}
                          ref={picker}
                          block
                          disabled={disableCondition}
                          value={item.value}
                          data={item.data}
                          style={{ width: 224 }}
                          searchable={item.searchable}
                          placeholder="Select"
                          countable={false}
                          disabledItemValues={
                            state.filters?.minAge || state.filters?.maxAge
                              ? STUDENT_AGE_GROUPS.map(
                                  (ageGroup) => ageGroup.value
                                )
                              : []
                          }
                          onChange={(newValue, event) =>
                            handleChange(
                              newValue,
                              event?.target.value,
                              item.name
                            )
                          }
                          onClean={() => handleClean(item.name)}
                          renderExtraFooter={() => (
                            <>
                              <Checkbox
                                disabled={
                                  item.name == "ageGroups" &&
                                  (state?.filters?.minAge ||
                                    state?.filters?.maxAge)
                                }
                                checked={
                                  item?.value?.length === item?.data?.length
                                }
                                onChange={(value, checked) =>
                                  handleCheckAll(item?.name, value, checked)
                                }
                              >
                                All {item?.label}
                              </Checkbox>
                              {item.name == "ageGroups" && (
                                <CustomAgeGroupView
                                  disabled={
                                    state.filters?.ageGroups?.length > 0
                                  }
                                  minAge={state.filters?.minAge}
                                  maxAge={state.filters?.maxAge}
                                  setMinAge={(value) => {
                                    dispatch({
                                      type: "setMinAge",
                                      payload: value,
                                    });
                                  }}
                                  setMaxAge={(value) => {
                                    dispatch({
                                      type: "setMaxAge",
                                      payload: value,
                                    });
                                  }}
                                  reset={() => {
                                    dispatch({
                                      type: "setMinAge",
                                      payload: null,
                                    });
                                    dispatch({
                                      type: "setMaxAge",
                                      payload: null,
                                    });
                                  }}
                                />
                              )}
                            </>
                          )}
                        />
                      )}
                      {errors[item.name] && (
                        <div className="alert alert-danger">
                          {errors[item.name]}
                        </div>
                      )}
                    </div>
                  </div>
                  {item.name === "country" &&
                  (disableCondition
                    ? reminders?.filters?.country
                    : state?.filters?.country) == "Afghanistan" ? (
                    <div key={index} className="col-md-6">
                      <div className="form-group">
                        <label className="f-med mb-1" htmlFor="province">
                          Province
                        </label>
                        <CheckPicker
                          name="province"
                          className="w-100"
                          placement="autoVerticalStart"
                          menuMaxHeight={200}
                          ref={picker}
                          block
                          disabled={disableCondition}
                          value={
                            disableCondition
                              ? reminders?.filters?.country_state
                              : state?.filters?.country_state
                          }
                          data={AFG_STATES}
                          countable={false}
                          style={{ width: 224 }}
                          searchable={false}
                          placeholder="Select"
                          onChange={(newValue, event) =>
                            handleChange(
                              newValue,
                              event?.target.value,
                              "provinces",
                            )
                          }
                          onClean={() => handleClean("provinces")}
                          renderExtraFooter={() => (
                            <Checkbox
                              checked={
                                state?.filters?.country_state?.length ===
                                AFG_STATES?.length
                              }
                              onChange={(value, checked) =>
                                handleCheckAll("provinces", value, checked)
                              }
                            >
                              All provinces
                            </Checkbox>
                          )}
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>
          <hr />
          <br />
          <div className="row mg-row mb-3">
            <div className="d-flex justify-content-between">
              <label className="f-med mb-1" htmlFor="Occurance">
                Occurance
                <span name="Occurance" className="text-danger">
                  *
                </span>
              </label>
              {!repeatReminder && state?.schedule?.type === "repeat" && (
                <Button
                  color="green"
                  className="ms-2 h-auto pe-0 "
                  appearance="link"
                  size="xs"
                  onClick={() => setRepeatReminder(true)}
                >
                  <i className="fa fa-pen me-2"></i>
                  Edit
                </Button>
              )}
            </div>
            <div className="col-12">
              <SelectPicker
                block
                className="w-100"
                data={[
                  { value: "once", label: "One Time" },
                  ...(flipper(featuresData?.features, "reminders_repeat_type")
                    ? [
                        {
                          value: "repeat",
                          label: repeatReminderSummary(
                            disableCondition
                              ? reminders?.schedule
                              : state?.schedule,
                          ),
                        },
                      ]
                    : []),
                  { value: "state", label: "State Based" }
                ]}
                disabled={disableCondition}
                onChange={(value) => {
                  if (value == state?.schedule?.type) return;
                  dispatch({
                    type: "setScheduleType",
                    payload: value,
                  });
                  dispatch({ type: "clearOccuranceFilters" });
                  if (value == "repeat") {
                    setRepeatReminder(true);
                  }
                  setErrors({ ...errors, type: null });
                }}
                value={
                  disableCondition
                    ? reminders?.schedule?.type
                    : state?.schedule?.type
                }
                name="occurance"
                searchable={false}
              />
              {errors?.type && (
                <div className="alert alert-danger">{errors?.type}</div>
              )}
            </div>
          </div>
          {/* once/repeat reminder select schedule */}
          {(disableCondition
            ? ['once', 'repeat'].includes(reminders?.schedule?.type)
            : ['once', 'repeat'].includes(state?.schedule?.type)) && (
            <div className="row mg-row mb-3">
              <label className="f-med mb-1" htmlFor="schedule">
                Schedule For
                <span name="text-danger" className="text-danger">
                  *
                </span>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      This time would be student`s timezone from wherever he or
                      she belongs.
                    </Tooltip>
                  }
                >
                  <i className="fa-solid fa-circle-info light-txt ms-2"></i>
                </Whisper>
              </label>

              <div className="col-8">
                <DatePicker
                  size="md"
                  placement="auto"
                  block
                  disabled={disableCondition}
                  className="w-100"
                  placeholder="Select Date"
                  format="yyyy-MM-dd"
                  shouldDisableDate={combine(
                    beforeToday(),
                    after(
                      (state?.schedule?.type == "repeat"
                        ? state?.schedule?.end_date
                        : null) || date_after_days(new Date(), 90)
                    )
                  )}
                  onChange={(date) => {
                    dispatch(
                      { type: "setStartDate", payload: date },
                      setErrors({ ...errors, start_date: null })
                    );
                  }}
                  value={
                    disableCondition
                      ? new Date(reminders?.schedule?.start_date)
                      : state?.schedule?.start_date
                        ? new Date(state?.schedule?.start_date)
                        : null
                  }
                />
                {errors?.start_date && (
                  <div className="alert alert-danger">{errors?.start_date}</div>
                )}
              </div>
              <div className="col-4">
                <DatePicker
                  format="HH:mm"
                  placeholder="Select Time"
                  disabled={disableCondition}
                  onChange={(date) => {
                    dispatch(
                      { type: "setStartTime", payload: date },
                      setErrors({ ...errors, start_time: null })
                    );
                  }}
                  value={
                    disableCondition
                      ? new Date(reminders?.schedule?.start_date)
                      : state?.schedule?.start_time
                        ? new Date(state?.schedule?.start_time)
                        : null
                  }
                />
                {errors?.start_time && (
                  <div className="alert alert-danger">{errors?.start_time}</div>
                )}
              </div>
            </div>
          )}
          {/* state reminder select state */}
          {(disableCondition
            ? reminders?.schedule?.type == "state"
            : state?.schedule?.type == "state") && (
            <div className="row mg-row mb-3">
              <SelectPicker
                block
                className="w-100"
                data={reminderStates?.data?.map((reminderState) => ({
                  value: reminderState.state,
                  label: reminderState.label,
                }))}
                disabled={disableCondition}
                onChange={(value) => {
                  dispatch({
                    type: "setScheduleState",
                    payload: value,
                  });
                  setErrors({ ...errors, state: null });
                }}
                value={
                  disableCondition
                    ? reminders?.schedule?.state
                    : state?.schedule?.state
                }
                name="occurance"
                searchable={false}
              />
              {errors?.state && (
                <div className="alert alert-danger">{errors?.state}</div>
              )}
            </div>
          )}
          {/* activity filters >> */}
          {flipper(featuresData?.features, "reminders_activity_filters") &&
            (disableCondition
              ? reminders?.schedule?.type == "repeat"
              : state?.schedule?.type == "repeat") && (
              <div className="row mg-row mb-3 radio-custom-grp">
                <div className="col-md-6">
                  <label className="f-med mb-1" htmlFor="inactiveSince">
                    InActive Since
                  </label>
                  <InputNumber
                    name="inactiveSince"
                    maxLength={10}
                    block
                    disabled={disableCondition}
                    postfix=" Days"
                    min={1}
                    max={365}
                    value={
                      disableCondition
                        ? reminders?.filters?.inactive_since_days
                        : state?.filters?.inactive_since_days
                    }
                    scrollable={false}
                    onChange={(value) =>
                      dispatch(
                        {
                          type: "setInactiveDays",
                          payload: value ? Math.trunc(value) : null,
                        },
                        setErrors({ ...errors, inactive_days: null })
                      )
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label className="f-med mb-1" htmlFor="activeSince">
                    But Active Atleast Once Since
                  </label>
                  <InputNumber
                    name="activeSince"
                    maxLength={10}
                    min={1}
                    max={365}
                    block
                    disabled={disableCondition}
                    postfix=" Days"
                    value={
                      disableCondition
                        ? reminders?.filters?.active_since_days
                        : state?.filters?.active_since_days
                    }
                    scrollable={false}
                    onChange={(value) =>
                      dispatch(
                        {
                          type: "setActiveDays",
                          payload: value ? Math.trunc(value) : null,
                        },
                        setErrors({ ...errors, inactive_days: null })
                      )
                    }
                  />
                </div>
                {errors?.inactive_days && (
                  <div className="alert alert-danger">
                    {errors?.inactive_days}
                  </div>
                )}
              </div>
            )}
          {flipper(featuresData?.features, "reminders_activity_filters") &&
            (disableCondition
              ? reminders?.schedule?.type == "once"
              : state?.schedule?.type == "once") && (
              <div className="row mg-row mb-3 radio-custom-grp">
                <div className="col-md-6">
                  <label className="f-med mb-1" htmlFor="inactiveSince">
                    InActive Since
                  </label>
                  <DatePicker
                    size="lg"
                    block
                    placeholder="Inactive Since Date"
                    disabled={disableCondition}
                    shouldDisableDate={combine(
                      after(
                        date_before_days(
                          state?.schedule?.start_date || new Date(),
                          1
                        )
                      ),
                      before(
                        date_after_days(state?.filters?.active_since_date, 1) ||
                          date_before_days(new Date(), 365)
                      )
                    )}
                    onChange={(date) =>
                      dispatch(
                        {
                          type: "setInactiveSinceDate",
                          payload: date,
                        },
                        setErrors({ ...errors, inactive_date: null })
                      )
                    }
                    value={
                      disableCondition
                        ? new Date(reminders?.filters?.inactive_since_date)
                        : state?.filters?.inactive_since_date
                          ? new Date(state?.filters?.inactive_since_date)
                          : null
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label className="f-med mb-1" htmlFor="activeSince">
                    But Active Atleast Once Since
                  </label>
                  <DatePicker
                    size="lg"
                    block
                    placeholder="Active since date"
                    disabled={disableCondition}
                    shouldDisableDate={after(
                      date_before_days(
                        state?.filters?.inactive_since_date,
                        1
                      ) || new Date()
                    )}
                    onChange={(date) =>
                      dispatch(
                        {
                          type: "setActiveSinceDate",
                          payload: date,
                        },
                        setErrors({ ...errors, inactive_date: null })
                      )
                    }
                    value={
                      disableCondition
                        ? new Date(reminders?.filters?.active_since_date)
                        : state?.filters?.active_since_date
                          ? new Date(state?.filters?.active_since_date)
                          : null
                    }
                  />
                </div>
                {errors?.inactive_date && (
                  <div className="alert alert-danger">
                    {errors?.inactive_date}
                  </div>
                )}
              </div>
            )}
          {/* >> end activity filters */}
          <div className="row">
            <div className="col-6">
              <Button onClick={onClose} appearance="subtle" block>
                Cancel
              </Button>
            </div>
            <div className="col-6">
              {(
                disableCondition
                  ? !reminders?.filters?.language_id?.length
                  : !state?.filters?.language_id?.length
              ) ? (
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={
                    <Tooltip>Please select your preferred language</Tooltip>
                  }
                >
                  <Button
                    type="submit"
                    color="green"
                    appearance="primary"
                    block
                    disabled={
                      disableCondition
                        ? !reminders?.filters?.language_id?.length
                        : !state?.filters?.language_id?.length
                    }
                  >
                    Next
                  </Button>
                </Whisper>
              ) : (
                <Button
                  type="submit"
                  color="green"
                  appearance="primary"
                  block
                  disabled={
                    disableCondition
                      ? !reminders?.filters?.language_id?.length
                      : !state?.filters?.language_id?.length
                  }
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        </Form>
      </div>

      {!!repeatReminder && (
        <RepeatForm
          {...{ state, dispatch }}
          open={!!repeatReminder}
          onConfirm={() => {
            dispatch({ type: "setScheduleFilters" });
            setRepeatReminder(false);
          }}
          onClear={() => {
            dispatch({ type: "clearRepeatFilters" });
          }}
          onClose={() => {
            setRepeatReminder(false);
          }}
        />
      )}
    </div>
  );
};

export default Schedule;
