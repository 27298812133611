import React, { useEffect, useReducer } from "react";
import PageTitle from "../../../components/page_title";
import useSWR from "swr";
import { fetcher } from "../../../utils/fetcher";
import {
  Table,
  Pagination,
  Form,
  InputPicker,
  CheckPicker,
  Button,
  DateRangePicker,
} from "rsuite";
import { STUDENT_AGE_GROUPS } from "../../../utils/constants";
import { AFGSTATES, COUNTRIES } from "../../analytics/attempts/reducer";
import {
  format_date_without_time_zone_a,
  format_end_of_day_in_time_zone,
  format_start_of_day_in_time_zone,
} from "../../../utils/date_formatter";
import { isLastWeek, isToday, isYesterday } from "../../dashboard/helper";
import { useParams } from "react-router-dom";
import { replaceEmptyValues } from "../../../utils/helpers";
const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;

const HeaderCell = (props) => (
  <OriginalHeaderCell {...props} style={{ padding: 10 }} />
);

const Cell = (propos) => <OriginalCell {...propos} style={{ padding: 10 }} />;

const initialState = {
  page: 1,
  limit: 10,
  startDate: null,
  endDate: null,
  age: null,
  gender: null,
  country: null,
  provinceIds: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setPage":
      return { ...state, page: action.payload };
    case "setLimit":
      return { ...state, limit: action.payload, page: 1 };
    case "setAge":
      return { ...state, age: action.payload };
    case "setGender":
      return { ...state, gender: action.payload };
    case "setCountry":
      return { ...state, country: action.payload };
    case "setProvinceIds":
      return { ...state, provinceIds: action.payload };
    case "setStartDate":
      return { ...state, startDate: action.payload };
    case "setEndDate":
      return { ...state, endDate: action.payload };
    default:
      return state;
  }
};

const Index = () => {
  const { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const language = localStorage.getItem("language");
  const link = localStorage.getItem("link");
  const { page, limit } = state;
  const setPage = (page) => dispatch({ type: "setPage", payload: page });
  const setLimit = (limit) => dispatch({ type: "setLimit", payload: limit });

  const { data: demoVideoRequests, isLoading } = useSWR(
    {
      url: `/api/v0/statistics/demo_video_requests?demo_video_id=${id}`,
      params: payload({ ...state }),
    },
    fetcher,
  );
  useEffect(() => {
    demoVideoRequests?.data.forEach((item) => replaceEmptyValues(item));
  }, [demoVideoRequests]);
  const startIndex = (page - 1) * limit;
  const endIndex = Math.min(startIndex + limit, demoVideoRequests?.total);
  return (
    <div className="container">
      <div className="mb-3">
        <div className="row align-items-center">
          <div className="col-md-4">
            <PageTitle title={"Demo Video Requests"} />
            <div className="mt-1">
              <span className="grey-txt pe-1">{link}</span>
              <span className="f-med">{language && `(${language})`}</span>
            </div>
          </div>
          <div className="col-md-8">
            <div className="d-flex justify-content-end">
              <Button
                appearance="ghost"
                className="px-3 me-2"
                color="green"
                onClick={() => {
                  (window.location.href = "/demo_videos"), localStorage.clear();
                }}
              >
                <i className="fa fa-angle-left"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="shadow-sm bg-white radius-5">
        <div className="top bb px-3 pt-3 pb-2">
          <Filters {...{ state, dispatch }} />
        </div>
        <div className="inner">
          <Table
            loading={isLoading}
            height={500}
            width={"100wv"}
            data={demoVideoRequests?.data || []}
          >
            <Column width={100}>
              <HeaderCell>No</HeaderCell>
              <Cell>
                {(rowData, rowIndex) => (
                  <div>{rowIndex + 1 + (page - 1) * limit}</div>
                )}
              </Cell>
            </Column>
            <Column width={100} flexGrow={3}>
              <HeaderCell>Fullname</HeaderCell>
              <Cell className="text-capitalize" dataKey="attributes.fullname" />
            </Column>
            <Column width={100} flexGrow={2}>
              <HeaderCell>Age</HeaderCell>
              <Cell dataKey="attributes.age" />
            </Column>
            <Column width={100} flexGrow={3}>
              <HeaderCell>Requested on</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div>
                    {format_date_without_time_zone_a(
                      rowData?.attributes.created_at,
                    )}
                  </div>
                )}
              </Cell>
            </Column>
            <Column width={100} flexGrow={2}>
              <HeaderCell>Gender</HeaderCell>
              <Cell dataKey="attributes.gender" />
            </Column>
            <Column width={100} flexGrow={3}>
              <HeaderCell>Country</HeaderCell>
              <Cell dataKey="attributes.country" />
            </Column>
            <Column width={100} flexGrow={2}>
              <HeaderCell>Province</HeaderCell>
              <Cell dataKey="attributes.country_state" />
            </Column>
          </Table>
        </div>
      </div>

      {((demoVideoRequests?.data || []).length >= 10 || page > 1) && (
        <div className="d-flex justify-content-between align-items-center">
          <div className="grey-txt mt-2 f-14">
            {startIndex + 1} to {endIndex && endIndex != 0 ? endIndex : ""} of{" "}
            {demoVideoRequests?.total}
          </div>

          <div className="pagination-outer">
            <Pagination
              prev
              next={!isLoading}
              ellipsis
              layout={["limit", "|", "pager"]}
              size="sm"
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={setLimit}
              limit={limit}
              limitOptions={[10, 30, 50]}
              total={demoVideoRequests?.total}
              maxButtons={10}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const Filters = ({ state, dispatch }) => {
  const renderValue = (value) => {
    if (isToday(value) || isYesterday(value) || isLastWeek(value)) {
      if (!isLastWeek(value)) {
        return [
          isToday(value) ? "Today" : isYesterday(value) ? "Yesterday" : "",
        ];
      }
    }

    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return `${formatter.format(value[0])} to ${formatter.format(value[1])}`;
  };

  const handleOk = (value) => {
    dispatch({
      type: "setStartDate",
      payload: format_start_of_day_in_time_zone(value[0]),
    });
    dispatch({
      type: "setEndDate",
      payload: format_end_of_day_in_time_zone(value[1]),
    });
  };

  const handleClean = () => {
    dispatch({ type: "setStartDate", payload: null });
    dispatch({ type: "setEndDate", payload: null });
  };

  return (
    <Form layout="inline" className="mb-0">
      <Form.Group>
        <Form.Control
          name="age"
          accepter={InputPicker}
          data={STUDENT_AGE_GROUPS}
          value={state.age}
          onChange={(value) => dispatch({ type: "setAge", payload: value })}
          placeholder="Select Age Groups"
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          name="gender"
          accepter={InputPicker}
          data={[
            {
              label: "Male",
              value: "male",
            },
            {
              label: "Female",
              value: "female",
            },
          ]}
          value={state.gender}
          onChange={(value) => dispatch({ type: "setGender", payload: value })}
          placeholder="Select Gender"
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          name="country"
          accepter={InputPicker}
          data={COUNTRIES}
          value={state.country}
          onChange={(value) => dispatch({ type: "setCountry", payload: value })}
          placeholder="Select Location"
        />
      </Form.Group>
      {state.country === "Afghanistan" && (
        <Form.Group>
          <Form.Control
            name="provinceIds"
            countable={false}
            accepter={CheckPicker}
            data={AFGSTATES}
            value={state.provinceIds}
            onChange={(value) =>
              dispatch({ type: "setProvinceIds", payload: value })
            }
            placeholder="Select Province"
          />
        </Form.Group>
      )}
      <DateRangePicker
        editable={false}
        shouldDisableDate={DateRangePicker.afterToday()}
        placement="bottomEnd"
        showOneCalendar
        className="date-filter"
        // defaultValue={dateSelected}
        renderValue={renderValue}
        placeholder="Select Date Range"
        onChange={(date) => {
          if (date) {
            dispatch({ type: "setStartDate", payload: date[0] });
            dispatch({ type: "setEndDate", payload: date[1] });
          }
        }}
        onOk={handleOk}
        onClean={handleClean}
      />
    </Form>
  );
};

const payload = ({
  startDate,
  endDate,
  age,
  gender,
  country,
  provinceIds,
  page,
  limit,
}) => ({
  start_date: startDate,
  end_date: endDate,
  age,
  gender,
  country,
  country_state: provinceIds,
  page,
  limit,
});

export default Index;
