import React, { Fragment, useEffect, useReducer, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PageTitle from "../../components/page_title";
import useSWR from "swr";
import axios from "axios";

import {
  Table,
  Pagination,
  Form,
  Checkbox,
  SelectPicker,
  InputPicker,
  CheckPicker,
  Whisper,
  Tooltip,
  DateRangePicker,
} from "rsuite";
import EditForm from "./form";
import { fetcher } from "../../utils/fetcher";
import { getAuthenticityToken } from "../../utils/authenticity_token";
import { STUDENT_AGE_GROUPS } from "../../utils/constants";
import { AFGSTATES, COUNTRIES } from "../analytics/attempts/reducer";
import { replaceEmptyValues } from "../../utils/helpers";
import { generateXLSXUrl } from "../../utils/xlsx";
import { ordinalize } from "../../utils/ordinalize";
import { format } from "date-fns"; // Importing date-fns for formatting dates
import CustomAgeGroupView from "../../components/custom_age_group";

const { Column, Cell: OriginalCell, HeaderCell: OriginalHeaderCell } = Table;

const HeaderCell = (props) => (
  <OriginalHeaderCell
    {...props}
    style={{
      padding: "0 10",
      display: "flex",
      alignItems: "center",
      lineHeight: "1.2",
    }}
  />
);

const Cell = (props) => <OriginalCell {...props} style={{ padding: 10 }} />;

const initialState = {
  page: 1,
  limit: 10,
  courseOptions: [],
  gradeOptions: [],
  courseType: "",
  courseIds: [],
  gradeIds: [],
  fullname: null,
  ageGroups: [],
  minAge: null,
  maxAge: null,
  ageGroupOptions: [],
  gender: null,
  languageId: null,
  country: null,
  provinces: [],
  mismatchEsl: null,
  activity: null,
  activity_start_date: null,
  activity_end_date: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setPage":
      return { ...state, page: action.payload };
    case "setLimit":
      return { ...state, limit: action.payload, page: 1 };
    case "setCourseOptions":
      return { ...state, courseOptions: action.payload, page: 1 };
    case "setGradeOptions":
      return { ...state, gradeOptions: action.payload, page: 1 };
    case "setCourseIds":
      return { ...state, courseIds: action.payload, page: 1 };
    case "setGradeIds":
      return { ...state, gradeIds: action.payload, page: 1 };
    case "setCourseType":
      return { ...state, courseType: action.payload, page: 1 };
    case "setFullname":
      return { ...state, fullname: action.payload };
    case "setAgeGroupOptions":
      return { ...state, ageGroupOptions: action.payload, page: 1 };
    case "setAgeGroups":
      return { ...state, ageGroups: action.payload, page: 1 };
    case "setGender":
      return { ...state, gender: action.payload, page: 1 };
    case "setLanguageId":
      return { ...state, languageId: action.payload, page: 1 };
    case "setCountry":
      return { ...state, country: action.payload, page: 1 };
    case "setProvinces":
      return { ...state, provinces: action.payload, page: 1 };
    case "setMismatchEsl":
      return { ...state, mismatchEsl: action.payload, page: 1 };
    case "setActivity":
      return { ...state, activity: action.payload, page: 1 };
    case "setMinAge":
      return { ...state, minAge: action.payload };
    case "setMaxAge":
      return { ...state, maxAge: action.payload };
    case "setDateRange":
      return {
        ...state,
        activity_start_date:
          Array.isArray(action.payload) && action.payload[0]
            ? format(
                new Date(action.payload[0].setHours(0, 0, 0, 0)),
                "yyyy-MM-dd 12:00 aa",
              ) + " +05:30" // Start date formatted
            : null,
        activity_end_date:
          Array.isArray(action.payload) && action.payload[1]
            ? format(
                new Date(action.payload[1].setHours(23, 59, 59, 999)),
                "yyyy-MM-dd 11:59 aa",
              ) + " +05:30" // End date formatted
            : null,
        page: 1,
      };
    case "setSearch":
      return {
        ...state,
        search: action.payload,
        fullname: action.payload,
        page: 1,
      };
    default:
      return state;
  }
};

const List = () => {
  const [searchParams] = useSearchParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { page, limit, ...restState } = state;
  const setPage = (page) => dispatch({ type: "setPage", payload: page });
  const setLimit = (limit) => dispatch({ type: "setLimit", payload: limit });
  const [studentToEdit, setStudentToEdit] = useState(null);
  const [editErrors, setEditErrors] = useState({});

  const { data: courses } = useSWR("/api/v0/courses", fetcher);
  const { data: grades } = useSWR("/api/v0/grades", fetcher);

  useEffect(() => {
    if (!courses) return;
    const courseItems = courses?.data?.map((course) => ({
      value: course.id,
      label: course.attributes.title,
    }));
    dispatch({
      type: "setCourseOptions",
      payload: courseItems,
    });
    if (searchParams.get("courses")) {
      dispatch({
        type: "setCourseIds",
        payload: searchParams.get("courses").split(","),
      });
    }
  }, [courses]);

  useEffect(() => {
    if (!grades) return;
    let gradeItems = grades?.data?.map((grade) => ({
      value: grade.id,
      label: ordinalize(grade.attributes.name),
    }));

    dispatch({
      type: "setGradeOptions",
      payload: gradeItems,
    });
    if (searchParams.get("grades")) {
      dispatch({
        type: "setGradeIds",
        payload: searchParams.get("grades").split(","),
      });
    }
  }, [grades]);

  useEffect(() => {
    dispatch({
      type: "setAgeGroupOptions",
      payload: STUDENT_AGE_GROUPS,
    });
    if (searchParams.get("age_groups")) {
      dispatch({
        type: "setAgeGroups",
        payload: searchParams.get("age_groups").split(","),
      });
    }
  }, []);

  const {
    data: students,
    isLoading,
    mutate,
  } = useSWR(
    {
      url: "api/v0/students",
      params: payload({
        ...state,
      }),
    },
    fetcher,
  );

  useEffect(() => {
    students?.data?.forEach((student) => {
      replaceEmptyValues(student);
      const words = student.attributes.fullname.split(" ");
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1),
      );
      student.attributes.fullname = capitalizedWords.join(" ");
    });
  }, [students]);

  useEffect(() => {
    if (searchParams.get("courseType")) {
      dispatch({
        type: "setCourseType",
        payload: searchParams.get("courseType"),
      });
    }
    if (searchParams.get("country")) {
      dispatch({
        type: "setCountry",
        payload: searchParams.get("country"),
      });
    }
    if (searchParams.get("country_state")) {
      dispatch({
        type: "setProvinces",
        payload: searchParams.get("country_state").split(","),
      });
    }
    if (searchParams.get("gender")) {
      dispatch({
        type: "setGender",
        payload: searchParams.get("gender"),
      });
    }
    if (searchParams.get("activity")) {
      dispatch({
        type: "setActivity",
        payload: searchParams.get("activity"),
      });
    }

    const startDate = searchParams.get("activity_start_date");
    const endDate = searchParams.get("activity_end_date");

    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    if (startDate && endDate) {
      const parsedStartDate = new Date(startDate);
      const parsedEndDate = new Date(endDate);

      if (!isNaN(parsedStartDate) && !isNaN(parsedEndDate)) {
        dispatch({
          type: "setDateRange",
          payload: [parsedStartDate, parsedEndDate],
        });
      }
    } else {
      dispatch({
        type: "setDateRange",
        payload: [firstDayOfMonth, today],
      });
    }
    if (searchParams.get("age_groups")) {
      dispatch({
        type: "setAgeGroups",
        payload: [searchParams.get("age_groups")],
      });
    }
    if (searchParams.get("min_age")) {
      dispatch({
        type: "setMinAge",
        payload: searchParams.get("min_age"),
      });
    }
    if (searchParams.get("max_age")) {
      dispatch({
        type: "setMaxAge",
        payload: searchParams.get("max_age"),
      });
    }
  }, [searchParams]);

  const startIndex = (page - 1) * limit;
  const endIndex = Math.min(startIndex + limit, students?.total);

  return (
    <div className="container">
      <div className="mb-3">
        <div className="row align-items-center">
          <div className="col-md-4">
            <PageTitle title={"Students"} />
          </div>
          <div className="col-md-8">
            <div className="d-flex justify-content-end align-items-center">
              <i className="fa-solid fa-circle text-danger small me-1"></i>
              <span className="me-3">Indicates mismatched ESL level</span>
              <a
                className="theme-btn"
                href={generateXLSXUrl(
                  "api/v0/students",
                  payload({ ...restState })
                )}
              >
                <i className="fa fa-download me-2"></i>
                Export
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="shadow-sm bg-white radius-5">
        <div className="top bb px-3 pt-3 pb-2">
          <Filters {...{ state, dispatch }} />
        </div>
        <div className="inner">
          <Table
            loading={isLoading}
            height={500}
            width={"100wv"}
            data={students?.data || []}
          >
            <Column width={200} flexGrow={4}>
              <HeaderCell>Full Name</HeaderCell>
              <Cell>
                {(rowData) => (
                  <span title={rowData?.attributes?.fullname}>
                    {rowData?.attributes?.fullname}
                  </span>
                )}
              </Cell>
            </Column>
            <Column width={100} flexGrow={1}>
              <HeaderCell>Age</HeaderCell>
              <Cell dataKey="attributes.age" />
            </Column>
            <Column width={100} flexGrow={2}>
              <HeaderCell>
                Preferred <br />
                Language
              </HeaderCell>
              <Cell dataKey="attributes.language" />
            </Column>
            <Column width={80} flexGrow={2}>
              <HeaderCell>Gender</HeaderCell>
              <Cell dataKey="attributes.gender" />
            </Column>
            <Column width={90} flexGrow={2}>
              <HeaderCell>Country</HeaderCell>
              <Cell dataKey="attributes.country" />
            </Column>

            <Column width={90} flexGrow={2}>
              <HeaderCell>Province</HeaderCell>
              <Cell dataKey="attributes.country_state" />
            </Column>
            <Column width={100} flexGrow={2}>
              <HeaderCell>
                Recommended <br />
                ESL Level
              </HeaderCell>
              <Cell dataKey="attributes.last_esl_level" />
            </Column>
            <Column width={100} flexGrow={2}>
              <HeaderCell>
                Attempted <br />
                ESL Level
              </HeaderCell>
              <Cell>
                {(rowData) => (
                  <div>
                    {rowData?.attributes?.last_esl_level != "N/A" &&
                    rowData?.attributes?.last_esl_level_attempt != "N/A" &&
                    rowData?.attributes?.last_esl_level_attempt !==
                      rowData?.attributes.last_esl_level ? (
                      <>
                        <div className="d-flex align-items-center">
                          {rowData?.attributes?.last_esl_level_attempt}
                          <i className="fa-solid fa-circle text-danger small ms-1"></i>
                        </div>
                      </>
                    ) : (
                      rowData?.attributes?.last_esl_level_attempt
                    )}
                  </div>
                )}
              </Cell>
            </Column>
            <Column width={100} flexGrow={2}>
              <HeaderCell>
                Certificate <br />
                Issued
              </HeaderCell>
              <Cell>
                {(rowData) =>
                  rowData?.attributes?.successfully_completed_courses
                    ?.length ? (
                    <Whisper
                      followCursor
                      speaker={
                        <Tooltip>
                          <>
                            Cetificates Issued for Courses
                            <ul>
                              {rowData?.attributes?.successfully_completed_courses?.map(
                                (course) => (
                                  <li key={course?.title}>{course?.title}</li>
                                )
                              )}
                            </ul>
                          </>
                        </Tooltip>
                      }
                    >
                      <span className="them-text">
                        {
                          rowData?.attributes?.successfully_completed_courses
                            ?.length
                        }
                      </span>
                    </Whisper>
                  ) : (
                    rowData?.attributes?.successfully_completed_courses?.length
                  )
                }
              </Cell>
            </Column>
            <Column width={100} flexGrow={2}>
              <HeaderCell className="text-center">
                Courses <br />
                Enrolled
              </HeaderCell>
              <Cell dataKey="attributes.enrolled_published_courses_count" />
            </Column>
            <Column width={88} fixed="right">
              <HeaderCell>Actions</HeaderCell>
              <Cell className="text-center">
                {(rowData) => (
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => setStudentToEdit(rowData)}
                    onClick={() => setStudentToEdit(rowData)}
                  >
                    <i className="light-txt py-2 fa-solid fa-pen"></i>
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
        </div>
      </div>

      {!!studentToEdit && (
        <EditForm
          open={!!studentToEdit}
          student={studentToEdit}
          errors={editErrors}
          onConfirm={({ fullname }) => {
            const { id } = studentToEdit;
            const url = `/api/v0/students/${id}`;
            const method = "PUT";
            const data = {
              student: {
                fullname,
              },
            };
            axios({
              method,
              url,
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getAuthenticityToken(),
              },
              data,
            })
              .then(() => {
                mutate();
                setStudentToEdit(null);
                setEditErrors({});
                return;
              })
              .catch((error) => {
                setEditErrors(error.response.data.errors);
              });
          }}
          onClose={() => {
            setStudentToEdit(null);
            setEditErrors({});
          }}
        />
      )}

      {((students?.data || []).length >= 10 || page > 1) && (
        <div className="d-flex justify-content-between align-items-center">
          <div className="grey-txt mt-2 f-14">
            {startIndex + 1} to {endIndex && endIndex != 0 ? endIndex : ""} of{" "}
            {students?.total}
          </div>

          <div className="pagination-outer">
            <Pagination
              prev
              next={!isLoading}
              ellipsis
              layout={["limit", "|", "pager"]}
              size="sm"
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={setLimit}
              limit={limit}
              limitOptions={[10, 30, 50]}
              total={students?.total}
              maxButtons={10}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const Filters = ({ state, dispatch }) => {
  const picker = React.useRef();
  const { data: languages } = useSWR("/api/v0/languages", fetcher);
  const customAgeRange = Boolean(state.minAge || state.maxAge);

  useEffect(() => {
    state?.country !== "Afghanistan" &&
      dispatch({ type: "setProvinces", payload: [] });
  }, [state?.country, dispatch]);

  const customTooltip = (item, items) => {
    const selectedLabels = items?.map((i) => i?.tooltip ?? i?.label).join(", ");
    return items.map((i, index) => (
      <Fragment key={index}>
        <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip arrow={false}>{selectedLabels}</Tooltip>}
        >
          <span>{i.label}</span>
        </Whisper>
        {index !== items.length - 1 && ", "}
      </Fragment>
    ));
  };

  return (
    <Form layout="inline" className="mb-0">
      <Form.Group>
        <Form.Control
          name="courseTypes"
          accepter={InputPicker}
          data={[
            {
              label: "Graded",
              value: "Graded",
            },
            {
              label: "OtherType",
              value: "OtherType",
            },
            {
              label: "ESL",
              value: "ESL",
            },
          ]}
          value={state.courseType}
          style={{ width: "165" }}
          placeholder="Select Course Type"
          onChange={(value) =>
            dispatch({ type: "setCourseType", payload: value })
          }
        />
      </Form.Group>
      <Form.Group>
        <CheckPicker
          ref={picker}
          name="courseIds"
          style={{ width: 165 }}
          data={state?.courseOptions ?? []}
          value={state.courseIds}
          placement="autoVerticalStart"
          placeholder="Select Course"
          menuMaxHeight={200}
          countable={false}
          onChange={(newValue) =>
            dispatch({ type: "setCourseIds", payload: newValue })
          }
          onClean={() => dispatch({ type: "setCourseIds", payload: [] })}
          renderExtraFooter={() => (
            <Checkbox
              checked={
                state?.courseIds?.length === state?.courseOptions?.length
              }
              onChange={(value, checked) => {
                dispatch({
                  type: "setCourseIds",
                  payload: checked
                    ? state?.courseOptions?.map(
                        (courseOption) => courseOption.value,
                      )
                    : [],
                });
              }}
            >
              All Course
            </Checkbox>
          )}
          renderValue={(value, items) => customTooltip(value, items)}
        />
      </Form.Group>
      <Form.Group>
        <CheckPicker
          ref={picker}
          name="gradeIds"
          style={{ width: 165 }}
          data={state?.gradeOptions ?? []}
          value={state.gradeIds}
          placement="autoVerticalStart"
          placeholder="Select Grade"
          menuMaxHeight={200}
          countable={false}
          onChange={(newValue) =>
            dispatch({ type: "setGradeIds", payload: newValue })
          }
          onClean={() => dispatch({ type: "setGradeIds", payload: [] })}
          renderExtraFooter={() => (
            <Checkbox
              checked={state?.gradeIds?.length === state?.gradeOptions?.length}
              onChange={(value, checked) => {
                dispatch({
                  type: "setGradeIds",
                  payload: checked
                    ? state?.gradeOptions?.map(
                        (gradeOption) => gradeOption.value,
                      )
                    : [],
                });
              }}
            >
              All Grade
            </Checkbox>
          )}
          renderValue={(value, items) => customTooltip(value, items)}
        />
      </Form.Group>
      <Form.Group>
        <CheckPicker
          ref={picker}
          name="ageGroups"
          style={{ width: 165 }}
          data={state.ageGroupOptions ?? []}
          value={state.ageGroups}
          placement="autoVerticalStart"
          placeholder="Select Age Groups"
          menuMaxHeight={200}
          countable={false}
          searchable={false}
          disabledItemValues={
            customAgeRange
              ? STUDENT_AGE_GROUPS.map((ageGroup) => ageGroup.value)
              : []
          }
          onChange={(newValue) =>
            dispatch({ type: "setAgeGroups", payload: newValue })
          }
          onClean={() => dispatch({ type: "setAgeGroups", payload: [] })}
          renderExtraFooter={() => (
            <>
              <Checkbox
                disabled={customAgeRange}
                checked={
                  state?.ageGroups?.length === state?.ageGroupOptions?.length
                }
                onChange={(value, checked) => {
                  dispatch({
                    type: "setAgeGroups",
                    payload: checked
                      ? state?.ageGroupOptions?.map(
                          (ageGroupOption) => ageGroupOption.value
                        )
                      : [],
                  });
                }}
              >
                All Age Groups
              </Checkbox>
              <CustomAgeGroupView
                disabled={state.ageGroups?.length > 0}
                minAge={state.minAge}
                maxAge={state.maxAge}
                reset={() => {
                  dispatch({ type: "setMinAge", payload: null });
                  dispatch({ type: "setMaxAge", payload: null });
                }}
                setMinAge={(value) =>
                  dispatch({ type: "setMinAge", payload: value })
                }
                setMaxAge={(value) =>
                  dispatch({ type: "setMaxAge", payload: value })
                }
              />
            </>
          )}
          renderValue={(value, items) => customTooltip(value, items)}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Control
          name="language"
          accepter={SelectPicker}
          searchable={false}
          data={(languages?.data || []).map((language) => ({
            label: language.attributes.name,
            value: language.id,
          }))}
          labelKey="label"
          valueKey="value"
          style={{ width: "160" }}
          onChange={(value) =>
            dispatch({ type: "setLanguageId", payload: value })
          }
          value={state.languageId}
          placeholder="Select Language"
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          name="gender"
          placeholder="Select Gender"
          accepter={InputPicker}
          style={{ width: "145" }}
          data={[
            {
              label: "Male",
              value: "male",
            },
            {
              label: "Female",
              value: "female",
            },
          ]}
          value={state.gender}
          onChange={(value) => dispatch({ type: "setGender", payload: value })}
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          name="location"
          placeholder="Select Country"
          accepter={SelectPicker}
          value={state?.country}
          style={{ width: "147" }}
          onChange={(country) =>
            dispatch({ type: "setCountry", payload: country })
          }
          data={COUNTRIES}
        />
      </Form.Group>
      {state?.country === "Afghanistan" && (
        <Form.Group>
          <Form.Control
            name="location"
            placeholder="Select Province"
            accepter={CheckPicker}
            value={state?.provinces}
            style={{ width: "155" }}
            onChange={(province) =>
              dispatch({
                type: "setProvinces",
                payload: province,
              })
            }
            countable={false}
            data={AFGSTATES}
          />
        </Form.Group>
      )}
      <Form.Group className="mb-2">
        <Form.Control
          name="mismatchedEsl"
          placeholder="Mismatch ESL?"
          accepter={SelectPicker}
          style={{ width: "145" }}
          data={[
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
          ]}
          labelKey="label"
          searchable={false}
          valueKey="value"
          onChange={(value) =>
            dispatch({ type: "setMismatchEsl", payload: value })
          }
          value={state.published}
          defaultValue={null}
        />
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Control
          name="activity"
          placeholder="Activity"
          accepter={SelectPicker}
          style={{ width: "145" }}
          data={[
            { label: "Certificate Issued", value: "certificate_issued" },
            { label: "Lesson Requested", value: "lesson_requested" },
          ]}
          labelKey="label"
          searchable={false}
          valueKey="value"
          onChange={(value) =>
            dispatch({ type: "setActivity", payload: value })
          }
          value={state.activity}
          defaultValue={null}
        />
      </Form.Group>

      <Form.Group controlId="daterange">
        <DateRangePicker
          placeholder="Select date range"
          value={[
            state.activity_start_date
              ? new Date(state.activity_start_date)
              : null,
            state.activity_end_date ? new Date(state.activity_end_date) : null,
          ]}
          onChange={(value) => {
            // Ensure value is an array of length 2
            dispatch({
              type: "setDateRange",
              payload:
                Array.isArray(value) && value.length === 2
                  ? value.map((date) => (date ? new Date(date) : null)) // Convert dates to Date objects or keep null
                  : [null, null], // Handle case where value is not an array of two
            });
          }}
        />
      </Form.Group>
    </Form>
  );
};

const payload = ({
  activity,
  activity_start_date,
  activity_end_date,
  fullname,
  courseType,
  courseIds,
  gradeIds,
  ageGroups,
  minAge,
  maxAge,
  gender,
  languageId,
  country,
  provinces,
  mismatchEsl,
  page,
  limit,
}) => ({
  activity: activity,
  activity_start_date: activity_start_date,
  activity_end_date: activity_end_date,
  fullname,
  course_type: courseType,
  course_id: courseIds,
  grade_id: gradeIds,
  age_groups: minAge && maxAge ? [`${minAge}_${maxAge}`] : ageGroups,
  gender,
  language_id: languageId,
  country,
  country_state: provinces?.length ? provinces : null,
  mismatch_esl: mismatchEsl,
  page,
  limit,
});

export default List;
