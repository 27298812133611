import React from "react";
import { InputNumber } from "rsuite";

const CustomAgeGroupView = ({ minAge, maxAge, reset, setMinAge, setMaxAge , disabled }) => {
  return (
    <div style={{ padding: 10 }}>
      <div className="d-flex justify-content-between">
        <label className="f-med mb-1" htmlFor="minAge">
          Custom Age Group
        </label>
        <button
          type="reset"
          className="text-danger f-small bg-transparent border-0 pb-1"
          onClick={reset}
        >
          Reset
        </button>
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        <InputNumber
          disabled={disabled}
          name="minAge"
          placeholder="Min"
          maxLength={2}
          block
          min={1}
          max={Number(maxAge || 100)}
          style={{ width: "100px" }}
          value={minAge}
          onChange={(value) => setMinAge(value)}
        />
        <InputNumber
          disabled={disabled}
          name="maxAge"
          placeholder="Max"
          maxLength={3}
          block
          min={Number(minAge || 1)}
          max={100}
          style={{ width: "100px" }}
          value={maxAge}
          onChange={(value) => setMaxAge(value)}
        />
      </div>
    </div>
  );
};

export default CustomAgeGroupView;
