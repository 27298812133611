// Core
import React, { useEffect, Fragment } from "react";

// Rsuite
import {
  DateRangePicker,
  CheckPicker,
  Checkbox,
  Whisper,
  Tooltip,
} from "rsuite";

// Components
import SelectFilter from "../../../components/select";

// Helper
import {
  GENDER_OPTIONS,
  STUDENT_AGE_GROUPS,
  COUNTRIES,
  AFGSTATES,
} from "../../../utils/constants";
import { today, last7Days, last30Days } from "./helper";
import { isLast30Days, isLastWeek, isToday } from "../../../utils/helpers";
import {
  format_end_of_day_in_time_zone,
  format_start_of_day_in_time_zone,
} from "../../../utils/date_formatter";
import CustomAgeGroupView from "../../../components/custom_age_group";

const Filter = ({ state, dispatch }) => {
  const picker = React.useRef();
  const { afterToday } = DateRangePicker;
  const { chartIndex, dataByChartIndex } = state;
  const customAgeRange =
    dataByChartIndex[chartIndex]?.minAge ||
    dataByChartIndex[chartIndex]?.maxAge;

  const renderValue = (value) => {
    if (isToday(value) || isLast30Days(value) || isLastWeek(value)) {
      return [
        isToday(value)
          ? "Today"
          : isLastWeek(value)
            ? "Last 7 Days"
            : "Last 30 Days",
      ];
    }
    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return `${formatter.format(value[0])} to ${formatter.format(value[1])}`;
  };

  const handleOk = (value) => {
    dispatch({
      type: "setStartDate",
      payload: value[0],
    });
    dispatch({
      type: "setEndDate",
      payload: value[1],
    });
  };

  const handleClean = () => {
    dispatch({
      type: "setStartDate",
      payload: format_start_of_day_in_time_zone(new Date()),
    });
    dispatch({
      type: "setEndDate",
      payload: format_end_of_day_in_time_zone(new Date()),
    });
  };

  const handleDate = (date, timeStatus) => {
    if (!timeStatus) {
      let hours = today.getHours();
      let minutes = today.getMinutes();
      let seconds = today.getSeconds();
      date[0].setHours(hours);
      date[0].setMinutes(minutes);
      date[0].setSeconds(seconds);
      date[1].setHours(hours);
      date[1].setMinutes(minutes);
      date[1].setSeconds(seconds);
    }
    dispatch({
      type: "setDataByChartIndex",
      payload: {
        ...dataByChartIndex,
        [state.chartIndex]: {
          ...dataByChartIndex[chartIndex],
          startDate: date[0],
          endDate: date[1],
        },
      },
    });
  };
  // TODO: Make it DRY
  const customTooltip = (item, items) => {
    const selectedLabels = items?.map((i) => i?.tooltip ?? i?.label).join(", ");
    return items.map((i, index) => (
      <Fragment key={index}>
        <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip arrow={false}>{selectedLabels}</Tooltip>}
        >
          <span>{i.label}</span>
        </Whisper>
        {index !== items.length - 1 && ", "}
      </Fragment>
    ));
  };

  useEffect(() => {
    if (dataByChartIndex[chartIndex]?.country != "Afghanistan") {
      dispatch({
        type: "setProvinceIds",
        payload: [],
      });
    }
  }, [dataByChartIndex[chartIndex]?.country]);

  return (
    <div className="row">
      <div className="col-md-3">
        <div className="form-group">
          <CheckPicker
            ref={picker}
            name="ageGroups"
            style={{ width: "100%" }}
            data={STUDENT_AGE_GROUPS ?? []}
            value={dataByChartIndex[chartIndex]?.ageGroups}
            placement="autoVerticalStart"
            placeholder="Select Age Groups"
            menuMaxHeight={200}
            countable={false}
            searchable={false}
            disabledItemValues={
              customAgeRange
                ? STUDENT_AGE_GROUPS.map((ageGroup) => ageGroup.value)
                : []
            }
            onChange={(newValue) =>
              dispatch({
                type: "setDataByChartIndex",
                payload: {
                  ...dataByChartIndex,
                  [chartIndex]: {
                    ...dataByChartIndex[chartIndex],
                    ageGroups: newValue,
                  },
                },
              })
            }
            onClean={() =>
              dispatch({
                type: "setDataByChartIndex",
                payload: {
              ...dataByChartIndex,
              [chartIndex]: {
                ...dataByChartIndex[chartIndex],
                ageGroups: [],
              },
                },
              })
            }
            renderExtraFooter={() => (
              <>
              <Checkbox
                  disabled={customAgeRange}
                checked={
                  dataByChartIndex[chartIndex]?.ageGroups?.length ===
                  STUDENT_AGE_GROUPS?.length
                }
                onChange={(value, checked) => {
                  dispatch({
                    type: "setDataByChartIndex",
                    payload: checked
                      ? {
                          ...dataByChartIndex,
                          [chartIndex]: {
                            ...dataByChartIndex[chartIndex],
                            ageGroups: STUDENT_AGE_GROUPS?.map(
                              (ageGroupOption) => ageGroupOption.value
                            ),
                          },
                        }
                      : {
                        ...dataByChartIndex,
                        [chartIndex]: {
                          ...dataByChartIndex[chartIndex],
                          ageGroups: [],
                        },
                      },
                  });
                }}
              >
                All Age Groups
              </Checkbox>
                <CustomAgeGroupView
                  disabled={dataByChartIndex[chartIndex]?.ageGroups?.length > 0}
                  minAge={dataByChartIndex[chartIndex]?.minAge}
                  maxAge={dataByChartIndex[chartIndex]?.maxAge}
                  reset={() => {
                    dispatch({
                      type: "setDataByChartIndex",
                      payload: {
                        ...dataByChartIndex,
                        [state.chartIndex]: {
                          ...dataByChartIndex[chartIndex],
                          minAge: null,
                          maxAge: null,
                        },
                      },
                    });
                  }}
                  setMinAge={(value) =>
                    dispatch({
                      type: "setDataByChartIndex",
                      payload: {
                        ...dataByChartIndex,
                        [state.chartIndex]: {
                          ...dataByChartIndex[chartIndex],
                          minAge: value,
                        },
                      },
                    })
                  }
                  setMaxAge={(value) =>
                    dispatch({
                      type: "setDataByChartIndex",
                      payload: {
                        ...dataByChartIndex,
                        [state.chartIndex]: {
                          ...dataByChartIndex[chartIndex],
                          maxAge: value,
                        },
                      },
                    })
                  }
                />
              </>
            )}
            renderValue={(value, items) => customTooltip(value, items)}
          />
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <SelectFilter
            value={dataByChartIndex[chartIndex]?.gender ?? ""}
            onChange={(e) =>
              dispatch({
                type: "setDataByChartIndex",
                payload: {
                  ...dataByChartIndex,
                  [chartIndex]: {
                    ...dataByChartIndex[chartIndex],
                    gender: e.target.value,
                  },
                },
              })
            }
            options={[
              {
                label: "All Genders",
                value: "",
              },
              ...GENDER_OPTIONS,
            ]}
          />
        </div>
      </div>

      <div className="col-md-3">
        <div className="form-group">
          <SelectFilter
            value={dataByChartIndex[chartIndex]?.country ?? ""}
            onChange={(e) =>
              dispatch({
                type: "setDataByChartIndex",
                payload: {
                  ...dataByChartIndex,
                  [chartIndex]: {
                    ...dataByChartIndex[chartIndex],
                    country: e.target.value,
                  },
                },
              })
            }
            options={[
              {
                label: "All Locations",
                value: "",
              },
              ...COUNTRIES,
            ]}
          />
        </div>
      </div>

      {dataByChartIndex?.[chartIndex]?.country === "Afghanistan" && (
        <div className="col-md-3">
          <div className="form-group">
            <CheckPicker
              className="w-100"
              placement="autoVerticalStart"
              menuMaxHeight={200}
              ref={picker}
              value={dataByChartIndex?.[chartIndex]?.provinceIds}
              data={AFGSTATES}
              countable={false}
              style={{ width: 224 }}
              searchable={false}
              placeholder="Select Province"
              onChange={(newValue) =>
                dispatch({
                  type: "setDataByChartIndex",
                  payload: {
                    ...dataByChartIndex,
                    [chartIndex]: {
                      ...dataByChartIndex[chartIndex],
                      provinceIds: Array.from(new Set([...newValue])),
                    },
                  },
                })
              }
              onClean={() =>
                dispatch({
                  type: "setDataByChartIndex",
                  payload: {
                    ...dataByChartIndex,
                    [chartIndex]: {
                      ...dataByChartIndex[chartIndex],
                      provinceIds: [],
                    },
                  },
                })
              }
              renderExtraFooter={() => (
                <Checkbox
                  checked={
                    dataByChartIndex[chartIndex]?.provinceIds.length ===
                    AFGSTATES?.length
                  }
                  onChange={(value, checked) => {
                    let provinces = [];
                    if (checked) {
                      provinces = AFGSTATES?.map(
                        (provinceOption) => provinceOption.value,
                      );
                    }
                    dispatch({
                      type: "setDataByChartIndex",
                      payload: {
                        ...dataByChartIndex,
                        [chartIndex]: {
                          ...dataByChartIndex[chartIndex],
                          provinceIds: provinces,
                        },
                      },
                    });
                  }}
                >
                  All provinces
                </Checkbox>
              )}
            />
          </div>
        </div>
      )}

      <div className="col-md-3">
        <div className="form-group">
          <DateRangePicker
            className="date-filter"
            editable={false}
            shouldDisableDate={afterToday()}
            placement="bottomEnd"
            showOneCalendar
            ranges={[
              {
                label: "Today",
                value: [new Date(), new Date()],
              },
              {
                label: "Last 7 days",
                value: [last7Days, today],
              },
              {
                label: "Last 30 days",
                value: [last30Days, today],
              },
            ]}
            value={[
              dataByChartIndex[chartIndex]?.startDate,
              dataByChartIndex[chartIndex]?.endDate,
            ]}
            renderValue={renderValue}
            placeholder="Select Range"
            onChange={(date) => {
              if (date) handleDate(date, false);
            }}
            onSelect={(date) => {
              if (date.length) handleDate(date, true);
            }}
            onOk={handleOk}
            onClean={handleClean}
          />
        </div>
      </div>
    </div>
  );
};

export default Filter;
